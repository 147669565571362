<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="show"
    max-width="500px"
    max-height="800px"
    persistent
    scrollable
  >
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-title class="pb-0" style="background: #7253cf; color: white">
        <img src="@/assets/teamModalLogo.svg" height="100" width="100" />
        <div class="ml-3 dialogTitle-text">Add new Record</div>
        <v-spacer></v-spacer>
        <v-icon
          style="margin-top: -80px; margin-right: -15px"
          dark
          large
          @click="toggleAddRecordModal({ show: false })"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-7">
        <v-form>
          <v-row no-gutters v-if="!school">
            <v-col cols="6">
              <v-autocomplete
                label="Class"
                v-model="grade"
                :items="gradeList"
                outlined
                dense
                :item-text="getFieldText"
                item-value="id"
                color="#7253CF"
                class="formFields"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="pb-4 px-1" style="margin-top: -12px">
              <v-checkbox
                type="checkbox"
                label="Select All Class"
                name="class"
                @click="selectclass()"
                >Select All Class</v-checkbox
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                label="Select All"
                name="chk"
                @click="selects()"
                >Select All</v-checkbox
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="1"
                id="wellness"
                @change="checkbox(1)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/wellness.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Wellness</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="2"
                @change="checkbox(2)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/imagination.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Imagination</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="3"
                @change="checkbox(3)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/zerohate.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Zero hate</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="4"
                @change="checkbox(4)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/fun.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Fun</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="5"
                @change="checkbox(5)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/integrity.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Integrity</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pb-4 px-1">
              <v-checkbox
                type="checkbox"
                name="chk"
                value="6"
                @change="checkbox(6)"
              ></v-checkbox>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="d-flex px-2">
                <img
                  src="@/assets/peTeacher/teamwork.svg"
                  width="45"
                  class="ml-2"
                  alt=""
                  style="margin-left: -109px !important; margin-top: 7px"
                />
              </div>
            </v-col>
            <v-col class="pb-4 px-1">
              <div class="videoLabel">Teamwork</div>
            </v-col>
          </v-row>
          <!--   <v-row no-gutters>
            <v-col>
              <v-radio-group
                class="radio-group py-0 ma-0 px-1 py-4"
                small
                v-model="video"
                column
              >
                <v-checkbox
                  :disabled="formLoading"
                  v-for="(item, i) in radioData"
                  :key="i"
                  color="#2C1963"
                  :value="item.value"
                  @change="checkbox(item.value)"
                >
                  <template v-slot:label>
                    <div class="d-flex px-2">
                      <img
                        :src="getImgUrl(`${item.img}.svg`)"
                        width="45"
                        class="ml-2"
                        alt=""
                      />
                      <div class="videoLabel ma-auto ml-4">
                        {{ item.label }}
                      </div>
                    </div>
                  </template>
                </v-checkbox>
              </v-radio-group>
            </v-col>
          </v-row>-->
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="rounded-lg dialogAction-btnText px-7"
          height="43px"
          dark
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
          :style="formStatus ? 'border:1px solid #38227A' : ''"
          v-if="type === 'add'"
        >
          <span>Add Record</span>
        </v-btn>
        <v-btn
          class="rounded-lg dialogAction-btnText px-7"
          height="43px"
          dark
          color="#38227A"
          :loading="formLoading"
          @click="submitForm"
          v-else
        >
          <span>Save</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_MASTER_GET_GRADE,
  API_VIDEO_SHOWCASE_POST,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddNewRecordModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      grade: null,
      gradeList: [],
      teacher_list: [],
      video: [],
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      wizfit_video_listss: [],
      selectall: [],
      radioData: [
        { label: "Wellness", url: "", value: 1, img: "wellness" },
        { label: "Imagination", url: "", value: 2, img: "imagination" },
        { label: "Zero Hate", url: "", value: 3, img: "zerohate" },
        { label: "Fun", url: "", value: 4, img: "fun" },
        { label: "Integrity", url: "", value: 5, img: "integrity" },
        { label: "Teamwork", url: "", value: 6, img: "teamwork" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "peTeacher/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
      schoolID: "teacherManagement/getSchool",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        this.toggleAddRecordModal({ show: value });
      },
    },
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    type() {
      return this.$store.state.peTeacher.addEditViewModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Record Added";
      } else {
        return "Record Updated";
      }
    },
    formStatus() {
      if (this.grade && this.video) {
        return false;
      } else {
        return true;
      }
    },
    school() {
      if (this.$route.matched[0].path.substring(1) === "school") return true;
      else return false;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddRecordModal: "peTeacher/toggleModal",
      showToast: "snackBar/showToast",
    }),
    getImgUrl(img) {
      return require("@/assets/peTeacher/" + img);
    },
    openModal() {
      if (this.type === "edit") {
        this.grade = this.$store.state.peTeacher.addEditViewModal.grade;
        this.video = this.$store.state.peTeacher.addEditViewModal.video;
      }
      this.getGrade();
    },
    checkbox(id) {
      this.wizfit_video_listss.push(id);
    },
    selects() {
      var ele = document.getElementsByName("chk");
      for (var i = 1; i < 7; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = true;
        this.wizfit_video_listss.push(i);
      }
    },
    checkmark() {
      var ele1 = document.getElementsByName("chk");
      this.check1 = true;
      if (ele1[1].type == "checkbox") ele1[1].checked = true;
      {
        this.check2 = true;
      }
      if (ele1[2].type == "checkbox") ele1[2].checked = true;
      {
        this.check3 = true;
      }
      if (ele1[3].type == "checkbox") ele1[3].checked = true;
      {
        this.check4 = true;
      }
      if (ele1[4].type == "checkbox") ele1[4].checked = true;
      {
        this.check5 = true;
      }
      if (ele1[5].type == "checkbox") ele1[5].checked = true;
      {
        this.check6 = true;
      }
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.grade = null;
      this.gradeList = [];
      this.video = null;
    },
    selectclass() {
      for (var j = 0; j < this.gradeList.length; j++) {
        this.teacher_list.push(this.gradeList[j].id);
      }
    },
    getFieldText(item) {
      return `${item.teacher_name} - ${item.grade_title}`;
    },

    getGrade() {
      const self = this;
      self.loading = true;
      const successHandler = (res) => {
        const data = res.data;
        console.log(data, "grades");
        self.gradeList = data.grade_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
        self.loading = false;
      };
      const formJson = {};
      formJson["campaign_id"] = this.selectedCampaignData.campaignData.id;
      formJson["school_id"] = this.schoolID;
      Axios.request_GET(
        API_MASTER_GET_GRADE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    submitForm() {
      this.wizfit_video_list = [];
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
        this.toggleAddRecordModal({ show: false });
        this.$emit("reload");
        location.reload();
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["campaign"] = this.selectedCampaignData.campaignData.id;
      formData["wizfit_video"] = null;
      formData["wizfit_video_list"] = this.wizfit_video_listss;
      formData["teacher_list"] = this.teacher_list;
      if (this.school) {
        formData["teacher"] =
          this.$store.state.peTeacher.addEditViewModal.teacherID;
        formData["grade"] =
          this.$store.state.peTeacher.addEditViewModal.teacherID;
        formData["student"] =
          this.$store.state.peTeacher.addEditViewModal.student;
      } else {
        formData["teacher"] = this.selectedCampaignData.organizationData.id;
        formData["grade"] = this.grade;
      }
      if (this.type === "add") {
        Axios.request_POST(
          API_VIDEO_SHOWCASE_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      } else {
        Axios.request_PATCH(
          API_VIDEO_SHOWCASE_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.videoLabel {
  font-family: LubalGraph Bd BT;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 161.5%;
  margin-left: -177px;
  margin-top: 16px;
  /* identical to box height, or 42px */

  color: #2c1963;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
</style>
